const SearchIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block w-6 h-6 "
    >
      <g fill="none" fillRule="evenodd">
        <path d="M1 1h22v22H1z" />
        <path
          d="M21.377 21.431L20.25 20.25l-4.112-4.12m2.279-5.505a7.792 7.792 0 11-15.584 0 7.792 7.792 0 0115.584 0z"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="1.2"
        />
      </g>
    </svg>
  );
}

export default SearchIcon;
