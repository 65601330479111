import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Link, useNavigate } from "react-router-dom";

import { STATIC_SERVER_URL } from "../../../config/config";
import Hamburger from "../hamburger/Hamburger";
import NavigationContent from "./NavigationContent";
import { authorizedState, cartState, searchValueState } from "atoms/atoms";

import "./navigation.scss";
import CartIcon from "../SVG/CartIcon";
import ProfileIcon from "../SVG/ProfileIcon";
import SearchIcon from "../SVG/SearchIcon";
import CartProductsIndicator from "../CartProductsIndicator/CartProductsIndicator";

function Navigation() {
  const [searchInputVisible, setSearchInputVisibility] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [basketItemCount, setBasketItemCount] = useState(0);
  const cartItems = useRecoilValue(cartState);
  const authorized = useRecoilValue(authorizedState);
  const setGlobalSearchString = useSetRecoilState(searchValueState);
  const navigate = useNavigate();

  useEffect(() => {
    const getProductsNumber = () => {
      // const basket = JSON.parse(sessionStorage.getItem("basket"));
      const productsNumber = cartItems?.reduce((acc, item) => acc + item.quantity, 0) || 0;
      setBasketItemCount(productsNumber);
    };

    getProductsNumber();
  }, [cartItems]);

  const handleInputChange = (e) => {
    setSearchString(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log("Enter gomb leütve!");
      setGlobalSearchString(e.target.value);
      navigate("/kereses");
    }
  };

  const handleSearchButtonClick = () => {
    const isVisible = searchInputVisible;
    setGlobalSearchString(searchString);
    setSearchInputVisibility(!isVisible);
    if (isVisible) {
      navigate("/kereses");
    }
  };

  return (
    <div className="navigation">
      <div className="navigation__left">
        <div className="show-desktop">
          <div className="honvedfc-logo">
            <Link to="/nyitolap">
              <img
                src={`${STATIC_SERVER_URL}/web/images/crests/khfc.png`}
                alt="kezdőlap"
                title="kezdőlap"
              />
            </Link>
          </div>
          <div className="title">
            <h1>Honvéd Shop</h1>
          </div>
        </div>
        <div className="menu show-desktop">
          <NavigationContent />
        </div>
      </div>

      <div className="navigation-functions">
        <div
          className={
            searchInputVisible ? "search-container search-container__visible" : "search-container"
          }
        >
          <input
            type="text"
            value={searchString}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        </div>
        <button className="search" onClick={handleSearchButtonClick}>
          <SearchIcon color="#1A1D21" />
        </button>
        {authorized && (
          <Link to="/profil" className="profile">
            <ProfileIcon />
          </Link>
        )}
        <Link to="/kosar" className="cart">
          {!!basketItemCount && <CartProductsIndicator items={basketItemCount} />}
          <CartIcon />
        </Link>
      </div>
      <Hamburger />
    </div>
  );
}

export default Navigation;
