import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavigationContent from "../navigation/NavigationContent";
import { STATIC_SERVER_URL } from "../../../config/config";

import "./hamburger.scss";
import SearchIcon from "../SVG/SearchIcon";
import { useSetRecoilState } from "recoil";
import { searchValueState } from "atoms/atoms";

const Hamburger = () => {
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  const [height, setHeight] = useState(0);
  const [searchString, setSearchString] = useState("");
  const navigate = useNavigate();
  const setGlobalSearchString = useSetRecoilState(searchValueState);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    setIsToggleOn((prev) => !prev);
  }, []);

  const handleScroll = useCallback(() => {
    setScroll(window.scrollY);
    setIsToggleOn(false);
  }, []);

  const handleNavigationChange = useCallback(() => {
    setIsToggleOn(false);
  }, []);

  useEffect(() => {
    const el = document.getElementById("hamburger-navigation");
    if (el) {
      setTop(el.offsetTop);
      setHeight(el.offsetHeight);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (scroll > top) {
      document.body.style.paddingTop = `${height}px`;
    } else {
      document.body.style.paddingTop = "0";
    }

    return () => {
      document.body.style.paddingTop = "0";
    };
  }, [scroll, top, height]);

  const handleInputChange = (e) => {
    setSearchString(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log("Enter gomb leütve!");
      setGlobalSearchString(e.target.value);
      navigate("/kereses");
    }
  };

  const handleSearchButtonClick = () => {
    setGlobalSearchString(searchString);
    navigate("/kereses");
  };

  return (
    <nav id="hamburger-navigation" className={scroll > top ? "fixed-nav" : ""}>
      <div className="honvedfc-logo">
        <Link to="/nyitolap">
          <img
            src={`${STATIC_SERVER_URL}/web/images/crests/khfc.png`}
            alt="kezdőlap"
            title="kezdőlap"
          />
        </Link>
        <div className="title">
          <h1>Honvéd Shop</h1>
        </div>
      </div>
      <button
        type="button"
        className={`menu-toggle ${isToggleOn ? "active" : ""}`}
        onClick={handleClick}
      >
        Menü
      </button>
      <div className={`menu-sub ${isToggleOn ? "show" : "hidden"}`}>
        <div className="navigation-headline">
          <div className="honvedfc-logo">
            <Link to="/nyitolap">
              <img
                src={`${STATIC_SERVER_URL}/web/images/crests/khfc.png`}
                alt="kezdőlap"
                title="kezdőlap"
              />
            </Link>
          </div>
          <div className="title">
            <h1>Menü</h1>
          </div>
        </div>
        <div className="hamburger-navigation">
          <NavigationContent onNavigationClicked={handleNavigationChange} />
          <div className="search-container search-container__visible">
            <input
              type="text"
              value={searchString}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <button className="search" onClick={handleSearchButtonClick}>
              <SearchIcon color="#fff" />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Hamburger;
